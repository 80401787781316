<template>
  <div class="page-wraper">
    <div id="loading-area"></div>
    <!-- header -->
    <header class="site-header mo-left header header-curve ext-header dark">
      <div class="middle-bar bg-dark">
        <div class="container-fluid">
          <!-- website logo  -->
          <div class="middle-area">
            <div class="logo-header" style="text-align: center">
              <a href="#"
                ><img
                  src="@/assets/imgs/logo.png"
                  alt=""
                  style="max-height: 120px; width: auto"
              /></a>
            </div>
            <div class="service-list">
              <ul>
                <li>
                  <i class="fa fa-phone"></i>
                  <h4 class="title">+2 01119167774</h4>
                  <span>Call Us</span>
                </li>
                <li>
                  <i class="la la-clock-o"></i>
                  <h4 class="title">08:00 AM - 03:00 PM</h4>
                  <span>Saturday To Thursday</span>
                </li>
                <li>
                  <i class="la la-map"></i>
                  <h4 class="title">Zahraa El Maadi</h4>
                  <span>Cairo, Egypt</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- main header -->
      <div class="sticky-header main-bar-wraper navbar-expand-lg">
        <div class="main-bar clearfix">
          <div class="container-fluid clearfix">
            <!-- website logo -->
            <div class="logo-header mostion" style="text-align: center">
              <a href="#"><img src="@/assets/imgs/logo.png" alt="" /></a>
            </div>
            <!-- nav toggle button -->
            <button
              class="navbar-toggler navicon justify-content-end"
              :class="{
                collapsed: !this.$store.getters.getNavbarCollapsed,
              }"
              id="btnNavbarToggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click.prevent="navbarToggler()"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <!-- extra nav -->
            <div class="extra-nav">
              <div class="extra-cell">
                <ul class="list-inline">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/citadel.international.school"
                      ><i class="fa fa-facebook social-icon-circle CC"></i
                    ></a>
                  </li>
                  <li>
                    <a href="#"
                      ><i class="fa fa-linkedin social-icon-circle"></i
                    ></a>
                  </li>
                  <li>
                    <a href="#"
                      ><i class="fa fa-twitter social-icon-circle"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      target="_blanck"
                      href="https://www.instagram.com/citadel.british.school/"
                    >
                      <i class="fa fa-instagram social-icon-circle"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- main nav -->
            <div
              class="header-nav navbar-collapse collapse justify-content-start"
              :class="{
                collapse: !this.$store.getters.getNavbarCollapsed,
                show: this.$store.getters.getNavbarCollapsed,
              }"
              id="navbarNavDropdown"
            >
              <div
                class="logo-header d-md-block d-lg-none"
                style="text-align: center"
              >
                <a href="#"><img src="@/assets/imgs/logo.png" alt="" /></a>
              </div>
              <ul class="nav navbar-nav">
                <li>
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'About-Us' }">About Us</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Academic' }">Academic</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Admissions' }"
                    >Admissions</router-link
                  >
                </li>
                <!-- <li>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfVt2LR-v46pW-HPaw_sSF_ATA2TV4no6a705_dgu3_GCAa2A/viewform"
                    target="_blank" rel="noopener noreferrer">
                    Students Admission
                  </a>
                </li> -->
                <li>
                  <router-link :to="{ name: 'Contact-Us' }"
                    >Contact Us</router-link
                  >
                </li>
                <li>
                  <a
                    href="https://forms.gle/XRknZ84PswhjHBtz6"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Careers</a
                  >
                </li>

                <!-- <li>
                  <a href="/dashboard/applications/create">Apply</a>
                </li> -->
                <li v-if="!this.$store.getters.isAuthenticated">
                  <router-link :to="{ name: 'Sign-Up' }">Sign Up/Login</router-link>
                </li>
                <li v-if="this.$store.getters.isAuthenticated">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li v-if="this.$store.getters.isAuthenticated">
                  <a @click="this.$store.dispatch('logout')">Logout</a>
                </li>
              </ul>
              <div class="dlab-social-icon">
                <ul class="list-inline">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/citadel.international.school"
                      style="margin: 5px"
                      ><i class="fa fa-facebook social-icon-circle"></i
                    ></a>
                  </li>
                  <li>
                    <a href="#" style="margin: 5px"
                      ><i class="fa fa-linkedin social-icon-circle"></i
                    ></a>
                  </li>
                  <li>
                    <a href="#" style="margin: 5px"
                      ><i class="fa fa-twitter social-icon-circle"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      target="_blanck"
                      href="https://www.instagram.com/citadel.british.school/"
                      style="margin: 5px"
                    >
                      <i class="fa fa-instagram social-icon-circle"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- main header END -->
    </header>
    <!-- header END -->

    <!-- Content -->
    <router-view />

    <!-- Newsletter -->
    <!-- <div class="section-full p-tb50 bg-primary text-white">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-7 m-md-b30">
            <h4>Subscribe To Our Newsletter</h4>
          </div>
          <div class="col-md-5">
            <form class="dzSubscribe style1" action="script/mailchamp.php" method="post">
              <div class="dzSubscribeMsg"></div>
              <div class="input-group">
                <input name="dzEmail" required="required" type="email" class="form-control"
                  placeholder="Your Email Address">
                <div class="input-group-addon">
                  <button disabled name="submit" value="Submit" type="submit"
                    class="site-button-secondry">Subscribe</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Footer -->
    <footer
      class="site-footer map-footer bg-black-1"
      style="border-top: 3px solid #781b20"
    >
      <!-- top footer -->
      <div class="footer-top">
        <div class="container-fluid">
          <div class="row equal-wraper">
            <div class="col-md-6 col-lg-6 col-sm-12 p-a0 equal-col">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.165885914244!2d31.356542215113393!3d29.974662281907673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583bd94be6e8a9%3A0x85553595faac853b!2sX9F5%2BVF9%2C%20El%20Basatin%2C%20Cairo%20Governorate!5e0!3m2!1sen!2seg!4v1647079673951!5m2!1sen!2seg"
                style="
                  border: 0;
                  width: 100%;
                  min-height: 300px;
                  height: 100%;
                  vertical-align: middle;
                "
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 equal-col address-block">
              <div class="p-tb60 p-lr15">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 m-b30">
                    <div
                      class="
                        icon-bx-wraper
                        bx-style-1
                        p-a20
                        radius-sm
                        br-col-w1
                      "
                    >
                      <div class="icon-content">
                        <h5 class="dlab-tilte">
                          <span class="icon-sm"
                            ><i class="ti-location-pin"></i
                          ></span>
                          Address
                        </h5>
                        <p class="op7">
                          Zahraa El Maadi - Area A1 - Investment Partition -
                          Behind Wataneya Gas Station - Cairo - Egypt.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 m-b30">
                    <div
                      class="
                        icon-bx-wraper
                        bx-style-1
                        p-a20
                        radius-sm
                        br-col-w1
                      "
                    >
                      <div class="icon-content">
                        <h5 class="dlab-tilte">
                          <span class="icon-sm"><i class="ti-email"></i></span>
                          E-mail
                        </h5>
                        <p class="m-b0 op7">info@cisbritishschool.org</p>
                        <p class="op7">support@cisbritishschool.org</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 m-b30">
                    <div
                      class="
                        icon-bx-wraper
                        bx-style-1
                        p-a20
                        radius-sm
                        br-col-w1
                      "
                    >
                      <div class="icon-content">
                        <h5 class="dlab-tilte">
                          <span class="icon-sm"><i class="ti-mobile"></i></span>
                          Phone Numbers
                        </h5>
                        <p class="m-b0 op7">+2 01119167774 - +2 01119167778</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 m-b30">
                    <div
                      class="
                        icon-bx-wraper
                        bx-style-1
                        p-a20
                        radius-sm
                        br-col-w1
                      "
                    >
                      <div class="icon-content">
                        <h5 class="dlab-tilte">
                          <span class="icon-sm"
                            ><i class="ti-alarm-clock"></i
                          ></span>
                          Office Hours
                        </h5>
                        <p class="m-b0 op7">
                          Saturday To Thursday - 08:00 AM - 03:00 PM
                        </p>
                        <p class="op7">Friday - Close</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- mid footer -->
      <div class="p-tb30 text-center bg-black-1 middle-footer">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h3 class="font-weight-300">LET'S CONNECT</h3>
              <div class="dlab-separator bg-white"></div>
              <ul class="list-inline m-t20">
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/citadel.international.school"
                    ><i class="fa fa-facebook social-icon-circle"></i
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><i class="fa fa-linkedin social-icon-circle"></i
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><i class="fa fa-twitter social-icon-circle"></i
                  ></a>
                </li>
                <li>
                  <a
                    target="_blanck"
                    href="https://www.instagram.com/citadel.british.school/"
                    ><i class="fa fa-instagram social-icon-circle"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- footer bottom part -->
      <div class="footer-bottom">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-9 col-sm-9 text-left">
              <span>
                Copyright © 2021 {{ this.$store.getters.getSchoolName }} all
                rights reserved, Developed By
                <a href="https://www.linkedin.com/in/haithamahmed113" target="_blank" style="color: blue">Haitham Ahmed</a>, Based on
                EduZone - Theme Forest
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer END-->
    <!-- scroll top button -->
    <button class="scroltop fa fa-chevron-up"></button>
  </div>
</template>
<script>
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const navbarToggler = async () => {
      store.commit('setNavbarCollapsed', true);
    };

    return { navbarToggler };
  },
};
</script>
<style scoped>
.middle-bar,
.address-block,
.footer-bottom {
  background-image: url("~@/assets/imgs/pt15.png") !important;
}

.middle-footer {
  background-image: url("~@/assets/imgs/bg2.png") !important;
  background-size: contain;
}
</style>